if($(window).width() > 992 ) {
    $('.drop').click(function(e){
        if(!$(this).hasClass('ready')) {
            e.preventDefault();
            $(this).addClass('ready');
            $('.drop-menu').css('opacity', '1').css('right', '0');
            $( ".drop-menu" ).on('mouseenter', function() {
                $( ".drop-menu" ).on('mouseleave', function() {
                    $(this).css('opacity', '0').css('right', '-2000px');
                    $('.drop').removeClass('ready');
                });
            });
        }
    });
}