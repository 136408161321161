function OpenAdModal() {
  $("#myModal").modal("show");
}

if ($("#myModal")) {
  OpenAdModal();
}


if ($('.pop-up-open')) {
  $('#product').val($('h1').text());
  
  $('.pop-up-open').on('click', function() {
    open();
  })

  $(document).keydown(function(e) {
    if (e.keyCode == 27) {
      close();
    }
  });
  
  $('.close').on('click', function() {
    close();
  })
}

function open() {
  $('.pop-up').css('display', 'flex');
  setTimeout(() => {
    $('.pop-up').css('opacity', '1');
  }, 100);
}

function close() {
  $('.pop-up').css('opacity', '0');
  setTimeout(() => {
    $('.pop-up').css('display', 'none');
  }, 100);
}